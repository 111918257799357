@font-face {
  font-family: 'Dream catcher';
  src: url(./assets/fonts/DreamCatcher.ttf) format('truetype');
}

@font-face {
  font-family: 'Aleo-Bold';
  src: url(./assets/fonts/Aleo-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Aleo-Italic';
  src: url(./assets/fonts/Aleo-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Aleo-Light';
  src: url(./assets/fonts/Aleo-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Aleo-Regular';
  src: url(./assets/fonts/Aleo-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Aleo-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aleo-bold {
  font-family: 'Aleo-Bold', sans-serif;
}

.aleo-italic {
  font-family: 'Aleo-Italic', sans-serif;
}

.aleo-light {
  font-family: 'Aleo-Light', sans-serif;
}

.aleo-regular {
  font-family: 'Aleo-Regular', sans-serif;
}